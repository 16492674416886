<template>
    <div class="vh-100 d-flex flex-column">

        <!-- HEADER CHAT -->
        <div style="background-color: #1B628C;cursor: pointer;" class="p-1" v-if="info" @click="modalProfileIsOpen = true">
            <div class="row align-items-center" style="gap:10px 0">
                <div class="col">
                    <div class="d-flex align-items-center " style="gap:10px 20px">
                        <div>
                            <img :src="info.user_picture ? info.user_picture : default_img"
                                class="rounded-circle" style="height:60px;width:60px;border:2px solid white" />
                        </div>
                        <div class="flex-fill">
                            <h3 class="text-white h4 mb-0">{{info.user_name}}</h3>
                            <div class="d-flex" style="gap:5px;margin-top:.15em">
                                <div class="badge badge-primary" v-if="info.shelter">
                                    <img src="@/static/img_assets/shelter.png" class="w-100 m-auto" style="max-width: 10px;" />
                                </div>
                                <div class="badge badge-primary" v-if="info.vet">
                                    <img src="@/static/img_assets/vet.png" class="w-100 m-auto" style="max-width: 10px;" />
                                </div>
                                <!-- <div class="badge badge-primary">
                                    <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto" style="max-width: 10px;" />
                                </div> -->
                                <!-- <div class="badge badge-primary">
                                    <img src="@/static/img_assets/hotel.png" class="w-100 m-auto" style="max-width: 10px;" />
                                </div> -->
                                <div class="badge badge-primary" v-if="info.trainer">
                                    <img src="@/static/img_assets/trainer.png" class="w-100 m-auto" style="max-width: 10px;" />
                                </div>
                                <div class="badge badge-primary" v-if="info.walker">
                                    <img src="@/static/img_assets/walker.png" class="w-100 m-auto" style="max-width: 10px;" />
                                    <!-- <img src="@/static/img_assets/groomer.png" class="w-100 m-auto" style="max-width: 10px;" /> -->
                                </div> 
                            </div>
                            <h3 class="text-muted h6 mb-0" style="margin-top: .25em;">Online {{moment(info.user_last_online).fromNow()}}</h3>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-4 text-right">
                    <i class="bx bx-bell text-white" style="font-size: 26px;"></i>
                </div> -->
            </div>
        </div>
        <!-- END HEADER -->

        <!-- BODY CHAT -->
        <div class="flex-fill" style="overflow-y: auto;">
            <div class="p-2 d-flex flex-column" style="gap:15px 10px">

                <template v-for="item in chats">
                    <!-- file -->
                    <div class="px-2 pt-2 pb-1 text-white ml-auto" v-if="item.is_me && item.chat_file_type == 'images'" style="
                        border-radius: 20px 20px 0px 20px;
                        background: #484848;
                        width: fit-content;
                    ">
                        <div>
                            <img :src="item.chat_file"
                                class="w-100 rounded" style="max-width: 200px;" /> 
                        </div>
                        <div class="d-flex" style="margin-top: .5em;">
                            <small class="">{{moment(item.created_at).fromNow()}}</small>
                        </div>
                    </div>

                    <div class="px-2 pt-2 pb-1" v-else-if="!item.is_me && item.chat_file_type == 'images'" style="
                        border-radius: 20px 20px 20px 0px;
                        background: #FFF;
                        width: fit-content;
                    ">
                        <div>
                            <img :src="item.chat_file"
                                class="w-100 rounded" style="max-width: 200px;" /> 
                        </div>
                        <div class="d-flex" style="margin-top: .5em;">
                            <small class="">{{moment(item.created_at).fromNow()}}</small>
                        </div>
                    </div>
                    <!-- end file -->

                    <!-- request adoption -->
                    <div @click="changeRoute('/shelter/adopt/summary/'+item.chat_is_request_adoption)" class="w-100" v-if="   item.chat_is_request_adoption" style="
                        background: white;
                        border:2px solid #FFAD32;
                        width: fit-content;
                        padding: 1em;
                        border-radius: 10px;
                    "> 
                        Requesting adoption for <strong>{{ item.summary.adoption.sha_name }}</strong>. <br/>
                        <div class="d-flex w-100 align-items-center justify-content-between" style="margin-top: .5em;">
                            <strong>Click for details.</strong>
                            <small class="">{{moment(item.created_at).fromNow()}}</small>
                        </div>
                    </div> 
                    <!-- end file -->

                    <div class="px-2 pt-2 pb-1" v-else-if="!item.is_me" style="
                        border-radius: 20px 20px 20px 0px;
                        background: #FFF;
                        width: fit-content;
                    ">
                        <div>{{item.chat_text}}</div>
                        <div class="d-flex">
                            <small class="ml-auto text-end">{{moment(item.created_at).fromNow()}}</small>
                        </div>
                    </div>

                    <div class="px-2 pt-2 pb-1 text-white ml-auto" v-else style="
                        border-radius: 20px 20px 0px 20px;
                        background: #484848;
                        width: fit-content;
                    ">
                        <div>{{item.chat_text}}</div>
                        <div class="d-flex">
                            <small class="">{{moment(item.created_at).fromNow()}}</small>
                        </div>
                    </div>
                </template> 

            </div>
        </div>
        <!-- END BODY CHAT -->

        <!-- BOTTOM NAV -->
        <div class="bg-white w-100" style="bottom: 0;z-index:9999">
            <div style="padding:.5em;border:1px solid rgba(0,0,0,1)" class="m-1 rounded border">
                <div class="d-flex w-100" style="gap:10px 10px">
                    <div @click="selectPicture()"><i class="bx bx-plus" style="font-size: 28px;color:#FFAD32"></i></div>
                    <input class="form-control flex-fill" id="input-chat" v-model="message" placeholder="Type anything..." style="border:unset;color:black" />
                    <div><i class="bx bxs-paper-plane ml-auto" @click="onSubmit()" style="font-size: 28px;color:#FFAD32"></i></div>
                </div>
            </div>
        </div>

        <form id="form">
            <input type="file" @change="previewFiles" name="chat_file" id="chat_file" class="d-none" accept="image/*" />
        </form>
        <!-- END BOTTOM NAV -->

        <b-modal v-model="modalProfileIsOpen" hide-footer title="Profile" centered>
            <template #modal-title>
                <div class="h4 mb-0">
                    Profile
                </div>
            </template>
            <div class="">
                <ModalProfile :info="info" />
            </div>
        </b-modal>

    </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import {BModal} from 'bootstrap-vue'
import ModalProfile from './modal-profile.vue'

import Cookies from 'universal-cookie'
let cookies = new Cookies() 

export default {
    components: {
        BModal, ModalProfile
    },
    computed: {
        chat_room(){
            return store.state.chat.CHAT_ROOM
        },
        chats(){
            return this.chat_room?.chats
        },
        info(){
            return this.chat_room?.info
        }
    },
    methods: {
        changeRoute(url){
            this.$router.push(url)
        },
        previewFiles(event) {
            let formData = new FormData($('#form')[0])
            formData.append('inp[chat_user_target]', this.id)
            formData.append('inp[user_id]', cookies.get('token'))
            formData.append('inp[chat_file_type]', 'images')

            store.dispatch('chat/SaveChat', formData).then(() => {
                store.dispatch('chat/GetChatRoom', {
                    source: cookies.get('token'),
                    target: this.id
                })
            })
        },
        selectPicture(){
            $('#chat_file').click()
        },
        onSubmit(){
            if(this.message.length > 0){
                let formData = new FormData()
                formData.append('inp[chat_user_target]', this.id)
                formData.append('inp[user_id]', cookies.get('token'))
                formData.append('inp[chat_text]', this.message)
    
                store.dispatch('chat/SaveChat', formData).then(() => {
                    store.dispatch('chat/GetChatRoom', {
                        source: cookies.get('token'),
                        target: this.id
                    })
                })
    
                this.message = ''
            }
        }
    },
    mounted(){
        store.dispatch('chat/GetChatRoom', {
            source: cookies.get('token'),
            target: this.id
        })
    },
    data(){
        return {
            modalProfileIsOpen: false,
            id: this.$route.params.id,
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            moment,
            message: ''
        }
    }
}

</script>

<style>
    #input-chat, #input-chat:active {
        color:black !important
    }
</style>